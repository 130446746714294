import React from "react";

const currentDate = new Date();
const currentYear = currentDate.getFullYear();

function Footer() {
  return (
    <div className=" mp-auto bg-white h-12 flex justify-center items-center">
      <div className="flex items-center">
        <p className="text-[#01499C] min-[250px]:text-[7px] min-[350px]:text-sm">
          Copyright © {currentYear} &nbsp;
            <a target="_blank" href="https://vikncodes.com/" >
              <span className="text-[#3f96f8] underline">ViknCodes</span>
            </a>. &nbsp;All rights reserved.
        </p>
      </div>
    </div>
  );
}

export default Footer;
