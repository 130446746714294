import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { lazy, Suspense, useState } from "react";
import { CircularProgress } from "@mui/material";
import { useMediaQuery } from "react-responsive";
//Importing pages
import Navbar from "../components/NavBar";
import Footer from "../components/footer";
import ProductCard from "../pages/SingleProductCard/ProductCards";
import About from "../pages/About/About";
import VikncodesAdmin from "../pages/Vikncodes_Admin/VikncodesAdmin";
const DashBoard = lazy(() => import("../pages/dashboard/dashboard"));
const Filter = lazy(() => import("../pages/filter/Filter"));
const SingleProduct = lazy(() =>
  import("../pages/SingleProduct/SingleProduct")
);
// Navigation Function
const Navigation = () => {
  const [loading, setLoading] = useState(false);
  const [Iscart, setIscart] = useState(false);

  const [search, setSearch] = useState("");
  const [dashboard, setDashboard] = useState(false);
  const isTabletMid = useMediaQuery({ query: "(max-width: 768px)" });
  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
  };
  if (loading) {
    return <>loading........</>;
  } else {
    return (
      <Router>
        <nav
          style={{
            height: "100%",
            // width: "100%",
            position: "fixed",
            zIndex: 100,
            top: 0,
            backgroundColor: "rgba(255, 255, 255, 1)",
          }}
        >
          <Navbar
            handleChangeSearch={handleChangeSearch}
            search={search}
            Iscart={Iscart}
            setIscart={setIscart}
            setSearch={setSearch}
          />
        </nav>
        <div
          style={{
            marginTop: isTabletMid ? "135px" : "75px",
            display: "flex",
            // textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="bg-[#EEEEEE]"
        >
          <Suspense fallback={<CircularProgress />}>
            <div className="bg-[#EEEEEE]" style={{ width: "1280px" }}>
              <Routes>
                <Route
                  path="/*"
                  element={<DashBoard setDashboard={setDashboard} />}
                ></Route>
                <Route
                  path="/products"
                  element={
                    <Filter setDashboard={setDashboard} search={search} />
                  }
                ></Route>
                <Route path="/about" element={<About />}></Route>
                <Route
                  path="/single-product"
                  element={
                    <SingleProduct
                      setDashboard={setDashboard}
                      Iscart={Iscart}
                      setIscart={setIscart}
                    />
                  }
                ></Route>
                <Route path="/productCard" element={<ProductCard />}></Route>
                <Route path="/vikncodes_admin" element={ <VikncodesAdmin/> } />
              </Routes>
            </div>
          </Suspense>
        </div>
        {dashboard === true ? <Footer /> : null}
      </Router>
    );
  }
};
export default Navigation;
