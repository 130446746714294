import Cardimage from "./ImageCard";
import React, { useRef } from "react";
import { useMediaQuery } from "react-responsive";

//image
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//css
// import "./Advertisements .css";
import test from "../components/images/test.png";
import { Media_Url } from "../settings";
const Carousel = (props) => {
  const is_1290 = useMediaQuery({ query: "(max-width: 1290px)" });
  const is_720 = useMediaQuery({ query: "(max-width: 768px)" });
  const is_361 = useMediaQuery({ query: "(max-width: 361px)" });
  const is_512 = useMediaQuery({ query: "(min-width: 512px)" });
  const is_1024 = useMediaQuery({ query: "(min-width: 1024px)" });

  const sliderRef = useRef();

  const settings = {
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 8000,
    speed: 1000,
    // cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
    customPaging: function (i) {
      return (
        <div
          style={{
            marginTop: is_1024 ? "-8.5rem" : is_512 ? "-45px" : "-25px",
            height: "2px",
            backgroundColor: "white",
            zIndex: "90",
          }}
        >
          <button />
        </div>
      );
    },
  };
  // afterChange: (current) => {
  //   const nextMedia = props.medias.list[current];
  //   if (props.adsLoader === false) {
  //     console.log("enter");
  //     const nextDelay = nextMedia.duration * 1000;
  //     props.setAutoplayDelay(nextDelay);
  //   }
  // },

  return (
    <div
      style={{ display: "flex", overflow: "hidden", justifyContent: "center" }}
    >
      <section
        style={{
          // width: is_361 ? "95vw" : is_720 ? "96vw" : is_1290 ? "100%" : "80%",
          width: "97vw",
          padding: "0px",
        }}
      >
        <div className="card-conatiner">
          <Slider {...settings} ref={sliderRef}>
            {props.carouselItems.items.map((i, index) => (
              <Cardimage key={index} url={Media_Url + i.BannerImage} index={index} />
            )) || <div className="h-[500px]" />}
          </Slider>
        </div>
      </section>
    </div>
  );
};
export default Carousel;
