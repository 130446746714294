import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import InputAdornment from "@mui/material/InputAdornment";
import negative from "../components/images/Group 1131.svg";
import positive from "../components/images/Group 1130.svg";
//
import { productDetailsSuccess } from "../Slices/productCartSlices";
import { Media_Url } from "../settings";
import { selectProductList } from "../Slices/productCartSlices";
import store from "../Store/store";
import { color } from "framer-motion";
import { useTranslation } from "react-i18next";
function SingleProductCart({
  Quantity,
  ProductColor,
  ProductCode,
  ProductName,
  item,
  SellingPrice,
  SubVariants,
  Size,
  index,
  stock,
  handleChangeInCart,
  outofstock,
  fetchDataGet,
}) {
  const dispatch = useDispatch();
  let matchingObj = item.data.ProductVariants.find(
    (obj) => obj.VariantName === ProductColor && obj.ProductVariantName === Size
  );
  const [t, i18n] = useTranslation("common");
  console.log(matchingObj, "-----------------");
  // console.log(id, "555555555555555555555");
  // const id = useSelector((state) => state.productDetails);
  // console.log(id, "Productlist");
  const handleRemoveFromCart = () => {
    const productList = selectProductList(store.getState());
    const updatedList = [...productList];
    updatedList.splice(index, 1);
    // console.log(updatedList, "<<<<<<<<<<<<<<updatedList");
    // dispatch(removeProduct(index));
    store.dispatch(
      productDetailsSuccess({
        ProductList: updatedList,
      })
    );
  };
  // const StockList = stock.find((arr) => arr.includes(id));

  // const handleChangeInCart = (e, v, name) => {
  //   console.log(v.VariantName);
  //   const productList = selectProductList(store.getState());
  //   const productIndexToUpdate = index; // Replace with the index of the item you want to update
  //   const productToUpdate = productList[productIndexToUpdate];
  //   let updatedProduct = {
  //     ...productToUpdate,
  //   };
  //   if (name === "color") {
  //     updatedProduct = {
  //       ...productToUpdate,
  //       ProductColor: v.VariantName,
  //       /* update the properties of the object as needed */
  //     };
  //   }
  //   if (name === "size") {
  //     updatedProduct = {
  //       ...productToUpdate,
  //       Size: v,
  //       /* update the properties of the object as needed */
  //     };
  //   }
  //   if (name === "-") {
  //     if (updatedProduct.Quantity > 1) {
  //       updatedProduct = {
  //         ...productToUpdate,
  //         Quantity: Quantity - 1,
  //         /* update the properties of the object as needed */
  //       };
  //     }
  //   }
  //   if (name === "+") {
  //     updatedProduct = {
  //       ...productToUpdate,
  //       Quantity: Quantity + 1,
  //       /* update the properties of the object as needed */
  //     };
  //   }
  //   // if (name === "Stock") {
  //   //   if (StockList) {
  //   //     updatedProduct = {
  //   //       ...productToUpdate,
  //   //       Quantity: StockList[1],
  //   //       /* update the properties of the object as needed */
  //   //     };
  //   //   } else {
  //   //     updatedProduct = {
  //   //       ...productToUpdate,
  //   //       /* update the properties of the object as needed */
  //   //     };
  //   //   }
  //   // }
  //   // if (name === "delete") {
  //   //   const updatedProductList = [...productList];
  //   //   updatedProductList.splice(index, 1); // remove the item at the given index
  //   //   dispatch(removeProduct(updatedProductList));
  //   // }

  //   const updatedProductList = [...productList];
  //   updatedProductList[productIndexToUpdate] = updatedProduct;

  //   store.dispatch(
  //     productDetailsSuccess({
  //       ProductList: updatedProductList,
  //     })
  //   );
  // };

  let colorList = item.data.Variants.filter(
    (item) => item.VariantName === ProductColor
  );
  colorList = colorList[0];
  const Variants = [{}];
  SellingPrice = Number(SellingPrice).toFixed(2);
  const isMobileMid = useMediaQuery({ query: "(max-width: 428px)" });

  const handleSellingPrice = () => {
    let totalPrice = SellingPrice * Quantity;
    return totalPrice;
  };

  const handleGetImage = () => {
    // console.log(colorList);
    if (colorList.VariantImage) {
      if (colorList.VariantImage.length > 0) {
        let image = Media_Url + colorList.VariantImage[0].VariantImage;
        return image;
      }
    }
  };
  useEffect(() => {}, []);
  return isMobileMid ? (
    <div className="  bg-[#F1F1F1] pb-2 rounded-sm mb-4  relative">
      <div
        style={{
          opacity: outofstock?.id_list?.includes(matchingObj.id) ? 0.5 : 1,
        }}
      >
        <div className="h-[5rem]  flex justify-around ">
          <div className="m-2 grow-0">
            <img src={handleGetImage()} alt="" className=" w-16 h-16 cover" />
          </div>
          <div className="flex flex-col max-w-[6rem] mx-[0.1rem] grow">
            <div className="text-left my-2">
              <p className="font-normal text-xs leading-3 font-poppins tracking-tighter text-gray-700">
                {ProductCode}
              </p>
              <p className=" font-medium text-sm leading-4 font-poppins tracking-wider text-black overflow-hidden whitespace-nowrap">
                {ProductName}
              </p>
            </div>
            <div className="flex">
              <Autocomplete
                sx={{
                  backgroundColor: "#ffffff",
                  marginRight: "0.5px",
                  borderRadius: "3px",
                  flexGrow: "1",
                  height: "32px",
                }}
                id="Product"
                disableClearable
                freeSolo
                options={item.data.Variants}
                getOptionLabel={(option) => option.VariantName || ""}
                name="Color"
                onChange={(e, v) => {
                  handleChangeInCart(e, v, "color", index, Quantity);
                }}
                // onChange={(e, v) => props.handleAutoComplete(e, v, "state")}
                value={colorList}
                renderInput={(params) => (
                  <StyledInput
                    // helperText={props.state.stateError ? "This is required" : ""}
                    {...params}
                    placeholder="Color"
                    sx={{
                      border: "0px solid ",
                      borderRadius: "3px",
                      borderColor: "#ffffff",
                      backgroundColor: "#ffffff",
                      minWidth: "5.25rem",
                      width: "100%",
                      padding: "0px",
                      // flexGrow: "5",
                    }}
                    id="outlined-controlled"
                    label="Color"
                    InputLabelProps={{
                      style: { color: "#000000", fontSize: "15px" },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        fontSize: "11px",
                        fontWeight: "400",
                        padding: "0.0px",
                      },
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className="flex flex-col max-w-[9.84rem] mx-[0.1rem]  grow">
            <div className="grid" style={{ gridTemplateColumns: "auto auto" }}>
              <div className="flex mx-[0.5rem] my-[0.71rem]">
                <button
                  onClick={(e) =>
                    handleChangeInCart(e, "v", "-", index, Quantity)
                  }
                >
                  <img src={negative} alt="" className="w-[20px] h-[20px]" />
                </button>
                <p className="min-w-3 text-xs mx-2	">{Quantity}</p>
                <button
                  onClick={(e) =>
                    handleChangeInCart(e, "v", "+", index, Quantity)
                  }
                >
                  <img src={positive} alt="" className="w-[20px] h-[20px]" />
                </button>
              </div>
            </div>
            <div className="flex ">
              <Autocomplete
                sx={{
                  backgroundColor: "#ffffff",
                  marginRight: ".5px",
                  borderRadius: "3px",
                  flexGrow: "1",
                  height: "32px",
                }}
                id="size"
                disableClearable
                freeSolo
                options={
                  SubVariants
                  // props.state.states
                  // props.state.countries ? props.state.countries : []
                }
                onChange={(e, v) => {
                  handleChangeInCart(e, v, "size", index, Quantity);
                }}
                // getOptionLabel={
                //   (option) => option.name || ""
                //   // option.Country_Name || ""
                // }
                name="size"
                // onChange={(e, v) => props.handleAutoComplete(e, v, "state")}
                value={Size}
                renderInput={(params) => (
                  <StyledInput
                    // helperText={props.state.stateError ? "This is required" : ""}
                    {...params}
                    placeholder="size"
                    sx={{
                      border: "0px solid ",
                      borderRadius: "3px",
                      borderColor: "#ffffff",
                      backgroundColor: "#ffffff",
                      minWidth: "5.25rem",
                      width: "100%",
                    }}
                    id="outlined-controlled"
                    label="Size"
                    InputLabelProps={{
                      style: { color: "#000000" },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        fontSize: "11px",
                        fontWeight: "400",
                        padding: "0.7px",
                      },
                    }}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mt-[0.2rem]">
          <div className="flex justify-left items-center text-left">
            <p className="ml-2 -mr-10 font-medium text-xs text-left leading-normal font-poppins tracking-wider text-[#BC3612]">
              {t("QR")}
              {handleSellingPrice()}
            </p>
          </div>
          <div className="flex align-center justify-center mx-[2.5px] my-auto">
            <DeleteIcon
              // color="error"
              sx={{ color: "#A62A11" }}
              fontSize="small"
              onClick={() => handleRemoveFromCart()}
            />
          </div>
        </div>
      </div>

      <div
        className="font-bold"
        style={{
          display: outofstock.id_list.includes(matchingObj.id)
            ? "flex"
            : "none",
          position: "absolute",
          top: "50%",
          left: "50%",
          color: "red",
          transform: " translate(-50%, -50%)",
        }}
      >
        out of stock
      </div>
    </div>
  ) : (
    // <div className="bg-[#F1F1F1] pb-2 rounded-md mb-4 h-[6rem] overflow-hidden flex w-[405px]  ">
    <div className="bg-[#F1F1F1] pb-2 rounded-md mb-4 relative  ">
      <div
        style={{
          opacity: outofstock?.id_list?.includes(matchingObj.id) ? 0.5 : 1,
          display: "flex",
        }}
      >
        <div className="m-2 grow-0">
          <img src={handleGetImage()} alt="" className="h-20 w-20 cover" />
        </div>
        <div className="flex flex-col max-w-[7.5rem] mx-1 grow">
          <div className="text-left my-2">
            <p className="font-normal text-xs leading-3 font-poppins tracking-tighter text-gray-700">
              {ProductCode}
            </p>
            <p className="font-normal text-base font-medium text-md leading-5 font-poppins tracking-wider text-black overflow-hidden whitespace-nowrap">
              {ProductName}
            </p>
          </div>
          <div className="flex grow">
            {/* <AutoCompleteContainer> */}
            <Autocomplete
              sx={{
                backgroundColor: "#ffffff",
                marginRight: "0.5px",
                borderRadius: "3px",
                flexGrow: "1",
                padding: "0",
                height: "32px",
              }}
              // id={FilterHeddding + SearchHeddding}
              options={item.data.Variants}
              getOptionLabel={(option) => option.VariantName || ""}
              name="Color"
              onChange={(e, v) => {
                handleChangeInCart(e, v, "color", index, Quantity);
              }}
              disableClearable
              freeSolo
              value={colorList}
              renderInput={(params) => (
                <StyledInput
                  // helperText={props.state.stateError ? "This is required" : ""}
                  {...params}
                  // label=""
                  placeholder="Color"
                  sx={{
                    border: "0px solid ",
                    borderRadius: "3px",
                    borderColor: "#ffffff",
                    backgroundColor: "#ffffff",
                    // width: "8.25rem",
                    // flexGrow: "5",
                  }}
                  // matchingObj.id="outlined-controlled"
                  label="Color"
                  InputLabelProps={{
                    style: { color: "#000000" },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      fontSize: "15px",
                      fontWeight: "400",
                      padding: "0px",
                    },
                  }}
                />
              )}
            />
            {/* </AutoCompleteContainer> */}
            {/* <Autocomplete
              sx={{
                backgroundColor: "#ffffff",
                marginRight: "0.5px",
                borderRadius: "3px",
                flexGrow: "1",
              }}
              // matchingObj.id="Color"
              disableClearable
              freeSolo
              options={
                item.data.Variants
                // props.state.states
                // props.state.countries ? props.state.countries : []
              }
              getOptionLabel={
                (option) => option.VariantName || ""
                // option.Country_Name || ""
              }
              name="Color"
              onChange={(e, v) => {
                handleChangeInCart(e, v, "color", index, Quantity);
              }}
              value={colorList}
              renderInput={(params) => (
                <CustomTextField
                  // helperText={props.state.stateError ? "This is required" : ""}
                  {...params}
                  // label=""
                  placeholder="Color"
                  sx={{
                    border: "0px solid ",
                    borderRadius: "3px",
                    borderColor: "#ffffff",
                    backgroundColor: "#ffffff",
                    // width: "8.25rem",
                    // flexGrow: "5",
                  }}
                  // matchingObj.id="outlined-controlled"
                  label="Color"
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      fontSize: "15px",
                      fontWeight: "400",
                      padding: "0px",
                    },
                  }}
                />
              )}
            /> */}
          </div>
        </div>
        <div className="flex flex-col max-w-[12.3rem] mx-1  grow">
          <div className="flex ">
            <div className="flex  mx-[0.5rem] my-[0.7rem]">
              <button
                onClick={(e) =>
                  handleChangeInCart(e, "v", "-", index, Quantity)
                }
              >
                <img src={negative} alt="" className="mr-0" />
              </button>
              <p className="min-w-[1rem] mx-1">{Quantity}</p>
              <button
                onClick={(e) =>
                  handleChangeInCart(e, "v", "+", index, Quantity)
                }
              >
                <img src={positive} alt="" />
              </button>
            </div>
            <div className="flex justify-center items-center">
              <p className=" font-medium text-base leading-normal font-poppins tracking-wider text-[#BC3612]">
                QR {handleSellingPrice()}
              </p>
            </div>
          </div>
          <div className="flex ">
            <Autocomplete
              sx={{
                backgroundColor: "#ffffff",
                marginRight: "0.5px",
                borderRadius: "3px",
                flexGrow: "1",
                padding: "0",
                height: "32px",
              }}
              // id={FilterHeddding + SearchHeddding}
              options={SubVariants}
              onChange={(e, v) => {
                handleChangeInCart(e, v, "size", index, Quantity);
              }}
              disableClearable
              freeSolo
              value={Size}
              renderInput={(params) => (
                <StyledInput
                  // helperText={props.state.stateError ? "This is required" : ""}
                  {...params}
                  // label=""
                  placeholder="size"
                  sx={{
                    border: "0px solid ",
                    borderRadius: "3px",
                    borderColor: "#ffffff",
                    backgroundColor: "#ffffff",
                    minWidth: "8.25rem",
                    // width: "8.25rem",
                    // flexGrow: "5",
                  }}
                  // matchingObj.id="outlined-controlled"
                  label="Size"
                  InputLabelProps={{
                    style: { color: "#000000" },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      fontSize: "15px",
                      fontWeight: "400",
                      padding: "0.6px",
                    },
                  }}
                />
              )}
            />
            <div className="flex align-center justify-center mx-1 my-auto cursor-pointer">
              <DeleteIcon
                // color="error"
                sx={{ color: "#A62A11" }}
                onClick={() => handleRemoveFromCart()}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="font-bold"
        style={{
          display: outofstock.id_list.includes(matchingObj.id)
            ? "flex"
            : "none",
          position: "absolute",
          top: "50%",
          left: "50%",
          color: "red",
          transform: " translate(-50%, -50%)",
        }}
      >
        out of stock
      </div>
    </div>
  );
}

export default SingleProductCart;
const CustomTextField = styled(TextField)`
  & label.Mui-focused {
    color: white;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      // border-color: white;
    }
  }
`;
// const AutoCompleteContainer = styled.div`
//   margin-bottom: "10px";
//   border-color: red !important;
//   width: 100%;
//   .MuiAutocomplete-inputRoot.MuiAutocomplete-input {
//     padding: 0;
//   }
//   .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
//     padding: 0px;
//   }

//   .MuiInputBase-marginDense.MuiOutlinedInput-marginDense,
//   div input {
//     padding: 0 !important;
//   }
// `;
const StyledInput = styled(TextField)`
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
    padding: 3px;
  }
  input {
    // margin-left: 5px;
    color: #000;
    padding: 0;
    font-size: 14px;
  }
  /* Change the white to any color */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
    border-color: red !important;
  }
  /*Change text in autofill textbox*/
  /* input:-webkit-autofill {
    -webkit-text-fill-color: yellow !important;
  } */

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  & textarea {
    min-height: 120px;
    max-height: 120px;
    color: #fff;
    font-weight: bold;
  }
  & .MuiOutlinedInput-root {
    /* border-radius: 25px; */
    /* border: 2px solid #e4e4e4; */
  }
  fieldset {
    border: 0;
  }
  &::-webkit-input-placeholder {
    color: #000;
  }
  & .MuiInputBase-input::-webkit-input-placeholder {
    color: #404040bb;
    opacity: 1;
    font-size: 12px;
    text-transform: capitalize;
    font-family: "poppinsregular";
    height: 15px;
  }
  & .MuiOutlinedInput-inputMarginDense {
    font-family: "poppinsregular";
    padding-top: 5.5px;
    padding-bottom: 5.5px;
    padding-right: 0px;
  }
  & .MuiInput-underline:before {
    border: 0 !important;
  }
  & .MuiInput-underline:after {
    border: 0;
  }
`;
